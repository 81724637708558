const get_started = {
    page_title: '시작하기',
    title: '성공하는 데 도움이 될 탐색 항목',
    subtitle: 'Seitrace의 가치를 빠르게 얻을 수 있는 몇 가지 방법',
    develop: {
        title: '개발: 앱을 통합하는 데 5분이면 충분합니다',
        title_cloud: '개발: 몇 분 안에 앱을 안전하게 통합하기',
        subtitle_cloud: '아니면 미리 만들어진 SDK와 자습서로 앱을 5분만에 통합해 보세요.',
    },
    customize: {
        title: '사용자 정의: 훌륭한 로그인 경험 전달하기',
        preview: {
            title: '방금 사용자 정의한 로그인 경험의 실시간 미리보기 확인하기',
            subtitle: '작동 방식을 확인하려면 지금 Seitrace 로그인 경험을 시도해보세요',
        },
        connector: {
            title: '더 많은 커넥터 추가하여 더 많은 소셜 로그인 방식 지원하기',
            subtitle: '비밀번호 없는 로그인을 시도하고 고객에게 안전하고 원활한 경험을 제공하세요',
        },
        continue_customizing: '사용자 정의 계속하기',
        try_now: '지금 시도해보세요',
        add_more: '더 추가하기',
    },
    secure: {
        title: '보안: 리소스 보호',
    },
    manage: {
        title: '관리: 제품 및 사용자에 대한 액세스 제어 정의',
        rbac: {
            title: '롤 기반 액세스 제어 추가하여 리소스 보호',
            subtitle: '다양한 사용 사례에 대해 확장 가능한 역할 권한부여를 통해 리소스 제어',
        },
        create_roles: '역할 생성',
    },
    view_all: '모두 보기 →',
};
export default Object.freeze(get_started);
